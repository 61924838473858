<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Reporte de Delivery</strong>
          </CCardHeader>
          <CCardBody>
            <b-row class="justify-content-md-center">

                
                <b-col md="2">
                  <b-form-group label="Usuario :">
                    <b-form-select v-model="report.id_user" :options="users"></b-form-select>
                  </b-form-group>
                </b-col>
                
                <b-col md="2">
                  <b-form-group label="Turno :">
                    <b-form-select v-model="report.turn" :options="turn"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Moneda :">
                    <b-form-select v-model="report.coin" :options="coin"></b-form-select>
                  </b-form-group>
                </b-col>


                <b-col md="2">
                  <b-form-group label="Desde:">
                    <b-form-input class="text-center" type="date" v-model="report.from"></b-form-input>
                    <small v-if="errors.from" class="form-text text-danger" >Seleccione un fecha</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Hasta:">
                    <b-form-input class="text-center" type="date" v-model="report.to"></b-form-input>
                    <small v-if="errors.to" class="form-text text-danger" >Seleccione un fecha</small>
                  </b-form-group>
                </b-col>

                

                <b-col sm="12" md="6">
                  <b-form-group>
                    <label>Cliente: </label>
                    <v-select  placeholder="Todos" class="w-100" :filterable="false" label="full_name" v-model="client" @search="SearchClients" :options="clients"></v-select>
                  </b-form-group>
                </b-col>


                <b-col md="1">
                  <b-form-group label=".">
                    <b-button @click="Report" title="Buscar" type="button" variant="primary" class="form-control"><i class="fas fa-search"></i></b-button>
                  </b-form-group>
                </b-col>

                <b-col md="1">
                  <b-form-group label=".">
                    <b-button @click="ExportExcel" title="Exportar a Excel" type="button" variant="success" class="form-control"><i class="fas fa-file-excel"></i></b-button>
                  </b-form-group>
                </b-col>

            </b-row>

            <div class="table-responsive mt-3">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="3%" class="text-center">#</th>
                    <th width="10%" class="text-center">Fecha</th>
                    <th width="10%" class="text-center">Nro Delivery</th>
                    <th width="45%" class="text-center">Cliente</th>
                    <th width="10%" class="text-center">Hora</th>
                    <th width="10%" class="text-center">Usuario</th>
                    <th width="5%" class="text-center">Moneda</th>
                    <th width="7%" class="text-center">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, it) in data_table" :key="it">
                    <td class="text-center">{{ it + 1 }}</td>
                    <td class="text-center"> {{ item.date }}</td>
                    <td class="text-center"> {{ item.code}}</td>
                    <td class="text-left"> {{ item.client_name +" - "+ item.client_document_number }}</td>
                    <td class="text-left"> {{ item.start_hour+" | "+item.end_hour }}</td>
                    <td class="text-left"> {{ item.user }}</td>
                    <td class="text-center"> {{ item.coin }}</td>
                    <td class="text-right"> {{ item.total }}</td>
                  </tr>
                  <tr>
                    <td class="text-center"></td>
                    <td class="text-center"></td>
                    <td class="text-center"> </td>
                    <td class="text-left"></td>
                    <td class="text-left"></td>
                    <td class="text-left"></td>
                    <td class="text-center"></td>
                    <td class="text-right"> {{ total.total }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
 
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

      <LoadingComponent :is-visible="isLoading"/>
  </div>
</template>

<script>
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";

import CodeToName from "@/assets/js/CodeToName";
var moment = require("moment");
import LoadingComponent from './../pages/Loading'
export default {
  name: "RquirementList",
  components:{
    LoadingComponent,
    vSelect,
  },
  data() {
    return {
      isLoading: false,
      module: 'ReportDelivery',
      role:1,
      data_table: [],
      total: {},
      report:{
        id_client:'all',
        id_user:'all',
        coin:'PEN',
        turn:'all',
        from:moment(new Date()).local().format("YYYY-MM-DD"),
        to:moment(new Date()).local().format("YYYY-MM-DD"),
      },
      coin:[
        {value:'PEN',text:'Soles'},
        {value:'USD',text:'Dolares'},
        {value:'CLP',text:'Pesos'},
      ],
      turn:[
        {value:'all',text:'Todos'},
        {value:'1',text:'Desayuno'},
        {value:'2',text:'Almuerzo'},
        {value:'3',text:'Cena'},
      ],
      users:[],
      clients: [],
      client:null,
      errors:{
        to:false,
        from:false,
      }
    };
  },
  mounted() {
    this.ListUsers();
  },
  methods: {
    SearchClients,
    ListUsers,

    Report,
    ExportExcel,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    id_establishment: function () {
      let establishment = window.localStorage.getItem("id_establishment");
      establishment = JSON.parse(je.decrypt(establishment));
      return establishment;
    }
  },
};

function SearchClients(search, loading) {
   let me = this;
    let url = this.url_base + "search-clients/" + search;
    if (search !== "") {
      loading(true);
      axios({
        method: "GET",
        url: url,
      }).then(function (response) {
            me.clients = response.data;
            loading(false);
      })
    }
}

function ListUsers() {

  let me = this;
  let url = this.url_base + "active-users";
  axios({
    method: "GET",
    url: url,
    headers: { token: me.token, module: me.module, role: me.role,},
  })
    .then(function (response) {
      me.users = [{value:'all',text:'-- Todos --'}];
      if (response.data.status == 200) {
        for (let index = 0; index < response.data.result.length; index++) {
          const element = response.data.result[index];
          me.users.push({value: element.id_user, text: element.user});
        }
      }
    })
}

function Report() {
  this.errors.from = false;
  this.errors.to = false;
  if (this.report.from.length == 0) {
    this.errors.from = true;
    Swal.fire({ icon: 'warning', text: 'Complete los cambos obligatorios', timer: 3000,})
    return false;
  }

  if (this.report.to.length == 0) {
    this.errors.to = true;
    Swal.fire({ icon: 'warning', text: 'Complete los cambos obligatorios', timer: 3000,})
    return false;
  }

  this.isLoading = true;
  let me = this;
  let url = this.url_base + "report/delivery";
  me.report.id_client = me.client == null ? 'all':me.client.id;
  let data = me.report;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { token: this.token, module: this.module,role: me.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.data_table = response.data.result.result;
        me.total = response.data.result.total;
        me.isLoading = false;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
        me.isLoading = false;
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}

function ExportExcel() {

  this.errors.from = false;
  this.errors.to = false;
  if (this.report.from.length == 0) {
    this.errors.from = true;
    Swal.fire({ icon: 'warning', text: 'Complete los cambos obligatorios', timer: 3000,})
    return false;
  }

  if (this.report.to.length == 0) {
    this.errors.to = true;
    Swal.fire({ icon: 'warning', text: 'Complete los cambos obligatorios', timer: 3000,})
    return false;
  }

  let me = this;
  let url = this.url_base + "excel-report-delivery/"+this.report.id_client +"/"+ this.report.coin+"/"+ this.report.from +"/"+ this.report.to+"/"+ this.report.id_user;
  window.open(url,'_blank');
}

</script>
